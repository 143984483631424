import { MessageSource } from '@botfabrik/engine-domain';
import useTranslation from '../../../../../locales/useTranslation';
import styles from './MessageSources.module.css';
import SourceIcon from './source-icon';

type Props = {
  sources: MessageSource[];
  onLinkClick: (href: string) => void;
};

const MessageSources = ({ sources, onLinkClick }: Props) => {
  const translate = useTranslation();

  const handleOnClick = (source: MessageSource) => () => {
    onLinkClick(source.url);
  };

  const handleOnKeyDown =
    (source: MessageSource) => (e: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onLinkClick(source.url);
      }
    };

  return sources.length ? (
    <ul
      className={styles.sourceList}
      aria-label={translate('bot-bubble.sources')}
    >
      {sources.map((s) => (
        <li key={s.url} className={styles.source}>
          <a
            role="button"
            tabIndex={0}
            aria-label={`${translate('bot-bubble.source.open')}: ${s.label}`}
            onClick={handleOnClick(s)}
            onKeyDown={handleOnKeyDown(s)}
          >
            <SourceIcon type={s.type} aria-hidden="true" />
            <div className={styles.label}>{s.label}</div>
          </a>
        </li>
      ))}
    </ul>
  ) : null;
};

export default MessageSources;

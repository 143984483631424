import { MessageSourceType } from '@botfabrik/engine-domain';
import Docx from '../../../../../components/icons/docx';
import File from '../../../../../components/icons/file';
import Pdf from '../../../../../components/icons/pdf';
import Web from '../../../../../components/icons/web';

type Props = {
  type: MessageSourceType;
} & React.SVGProps<SVGSVGElement>;

const SourceIcon = ({ type, ...rest }: Props) => {
  switch (type) {
    case 'WEBSITE':
      return <Web {...rest} />;
    case 'PDF':
      return <Pdf {...rest} />;
    case 'DOCX':
      return <Docx {...rest} />;
    default:
      return <File {...rest} />;
  }
};

export default SourceIcon;

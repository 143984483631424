import Docx from '../../components/icons/docx';
import File from '../../components/icons/file';
import Pdf from '../../components/icons/pdf';
import Text from '../../components/icons/text';
import Web from '../../components/icons/web';
import { KnowledgeBaseType } from '../../types';

type Props = {
  type: KnowledgeBaseType;
} & React.SVGProps<SVGSVGElement>;

const KnowledgeBaseTypeIcon = ({ type, ...rest }: Props) => {
  switch (type) {
    case 'WEBSITE':
      return <Web {...rest} />;
    case 'PDF':
      return <Pdf {...rest} />;
    case 'DOCX':
      return <Docx {...rest} />;
    case 'TEXT':
      return <Text {...rest} />;
    default:
      return <File {...rest} />;
  }
};

export default KnowledgeBaseTypeIcon;

import {
  Button,
  DetailMessage as DetailMessageType,
  MarkdownMessage as MarkdownMessageType,
  Message as MessageType,
  MessageTypeEnum,
  TextMessage as TextMessageType,
  UserType,
} from '@botfabrik/engine-domain';
import React, { forwardRef } from 'react';
import { ChatMessage, TranscriptItemReaction } from '../../../../types';
import BotMessage from './bot-message';
import DetailMessage from './detail-message';
import GuestMessage from './guest-message';
import MarkdownMessage from './markdown-message';
import TextMessage from './text-message';

interface Props {
  message: ChatMessage;
  commands: string[];
  isLastMessage: boolean;
  isFirstMessageBySender: boolean;
  isLastMessageBySender: boolean;
  onButtonClick: (button: Button) => void;
  onLinkClick: (href: string) => void;
  onReaction: (actionId: string, reaction: TranscriptItemReaction) => void;
}

const Message = forwardRef<HTMLLIElement, Props>(
  (
    {
      message,
      commands,
      isLastMessage,
      isFirstMessageBySender,
      isLastMessageBySender,
      onReaction,
      onButtonClick,
      onLinkClick,
    },
    ref
  ) => {
    const msgCmp = getMessageCmp(message, commands, onButtonClick, onLinkClick);
    switch (message.sender.type) {
      case UserType.GUEST: {
        return (
          <GuestMessage
            ref={ref}
            isFirstMessageBySender={isFirstMessageBySender}
            isLastMessageBySender={isLastMessageBySender}
          >
            {msgCmp}
          </GuestMessage>
        );
      }
      default: {
        const handleOnReaction = (reaction: TranscriptItemReaction) =>
          onReaction(message._id, reaction);
        return (
          <BotMessage
            ref={ref}
            message={message}
            isAnimated={false}
            isLastMessage={isLastMessage}
            isFirstMessageBySender={isFirstMessageBySender}
            isLastMessageBySender={isLastMessageBySender}
            onReaction={handleOnReaction}
          >
            {msgCmp}
          </BotMessage>
        );
      }
    }
  }
);

const getMessageCmp = (
  message: MessageType,
  commands: string[],
  onButtonClick: (button: Button) => void,
  onLinkClick: (href: string) => void
) => {
  switch (message.type) {
    case MessageTypeEnum.TEXT: {
      const textMessage = message as TextMessageType;
      return (
        <TextMessage
          text={textMessage.text}
          commands={commands}
          sources={message.sources || []}
          onLinkClick={onLinkClick}
        />
      );
    }
    case MessageTypeEnum.DETAIL: {
      const detailMessage = message as DetailMessageType;
      return (
        <DetailMessage
          title={detailMessage.title}
          text={detailMessage.text}
          subtitle={detailMessage.subtitle}
          imageUrl={detailMessage.imageUrl}
          itemUrl={detailMessage.itemUrl}
          buttons={detailMessage.buttons}
          sources={message.sources || []}
          onButtonClick={onButtonClick}
          onLinkClick={onLinkClick}
        />
      );
    }
    case MessageTypeEnum.MARKDOWN: {
      const markdownMessage = message as MarkdownMessageType;
      return (
        <MarkdownMessage
          markdown={markdownMessage.markdown}
          sources={markdownMessage.sources || []}
          onLinkClick={onLinkClick}
        />
      );
    }
  }
};

const areEqual = (prevProps: Props, nextProps: Props) =>
  prevProps.message._id === nextProps.message._id &&
  prevProps.message.reaction === nextProps.message.reaction &&
  prevProps.isLastMessage === nextProps.isLastMessage &&
  prevProps.isFirstMessageBySender === nextProps.isFirstMessageBySender &&
  prevProps.isLastMessageBySender === nextProps.isLastMessageBySender;

export default React.memo(Message, areEqual);

import { MessageSource } from '@botfabrik/engine-domain';
import messageStyle from '../../../Message.module.css';
import MessageSources from '../message-sources';
import Text from '../Text';

type Props = {
  text: string;
  commands: string[];
  sources: MessageSource[];
  onLinkClick: (href: string) => void;
};
const TextMessage = ({ text, commands, sources, onLinkClick }: Props) => (
  <div className={messageStyle.bubbleContent}>
    <Text text={text} commands={commands} onLinkClick={onLinkClick} />
    <MessageSources sources={sources} onLinkClick={onLinkClick} />
  </div>
);

export default TextMessage;
